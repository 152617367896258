
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
.market-v2__wrapper {
  @include media(lg) {
    display: grid;
    grid-column-gap: 48px;
    grid-template-rows: auto 1fr;
    grid-template-columns: 432px 1fr;
  }

  @include media(xl) {
    grid-template-columns: 452px 1fr;
  }
}

.market-v2__title {
  @include media(md, md-max) {
    width: 456px;
  }

  @include media(lg) {
    grid-row: 1/2;
    grid-column: 1/2;
  }
}

// TODO: выпилить margin-top, когда обновится UiTypograhyGroup
.market-v2__description {
  word-break: break-word;
  margin-top: 16px;
  color: var(--text-secondary-color);

  @include media(md, md-max) {
    width: 456px;
  }

  @include media(lg) {
    grid-row: 2/-1;
    grid-column: 1/2;
    margin-top: 24px;
  }
}

.market-v2__media {
  position: relative;
  margin-top: 32px;
  border-radius: 16px;
  overflow: hidden;

  @include media(sm-max) {
    padding-top: calc(100% / 16 * 9);
  }

  @include media(md) {
    width: 696px;
    height: 391px;
  }

  @include media(lg) {
    grid-row: 1/-1;
    grid-column: 2/-1;
    width: 448px;
    height: 252px;
    margin-top: 0;
    border-radius: 20px;
  }

  @include media(xl) {
    width: 668px;
    height: 376px;
  }

  .ui-video,
  .ui-picture {
    height: 100%;
  }

  img,
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include media(sm-max) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
